<template>
<!--    <div class="container py-3 py-lg-5 px-4">-->
  <component :is="layout || 'div'">
    <router-view/>
  </component>

<!--    </div>-->
</template>
<script setup>
import {onMounted, provide, shallowRef} from "vue";
import layouts from "./layouts/layouts.js";
import router from "./router/index.js";
import UserLayout from "./layouts/UserLayout.vue";
import {notification} from "ant-design-vue";

const layout = shallowRef('div');

router.afterEach((to) => {
  layout.value = layouts[to.meta.layout] || UserLayout;
})

provide('app.layout', layout);

let notificationShown = false // Flag to track if notification is already displayed
// Add the global event listener inside the `onMounted` lifecycle hook
onMounted(() => {
  window.addEventListener('vite:preloadError', (event) => {
    console.error('Preload error:', event.payload)

    if (!notificationShown) {
      notificationShown = true

      // Display a notification
      notification.warn({
        message: 'Page Refresh',
        description: 'Your page is reloading to update.',
        placement: 'bottom',
        duration: 3, // Notification stays for 3 seconds
      })

      // Wait for 5 seconds and then reload the page
      setTimeout(() => {
        window.location.reload()
        notificationShown = false // Reset the flag after reloading
      }, 3000)
    }
  })
})
</script>
<style scoped></style>
