// websocketManager.js
/* eslint-disable-next-line no-unused-vars */
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import { notification } from 'ant-design-vue'
import router from '../../../router/index.js'
import { getTokenSetLocal } from '@/util/localTokenAccess'
import { useIssuesStore } from '@/stores/issuesStore.js'

class WebSocketManager {
  constructor() {
    this.echo = null
    this.listeners = new Map()
    this.accessToken = getTokenSetLocal().accessToken
  }

  initialize() {
    if (this.echo)
      return

    this.echo = new Echo({
      broadcaster: 'reverb',
      key: import.meta.env.VITE_REVERB_APP_KEY,
      wsHost: import.meta.env.VITE_REVERB_HOST,
      wsPort: import.meta.env.VITE_REVERB_PORT,
      forceTLS: import.meta.env.VITE_REVERB_SCHEME === 'https',
      authEndpoint: `${import.meta.env.VITE_REVERB_HOST}/broadcasting/auth`,
      enabledTransports: ['ws', 'wss'],
      auth: {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`,
          'access-control-allow-origin': '*',
        },
      },
    })
  }

  subscribeToAdminChannel() {
    if (!this.echo) {
      console.error('Echo is not initialized')
      return
    }

    const channel = this.echo.channel(`admin.dashboard`)

    channel.listen('FastTrackExtractionCompletedEvent', (e) => {
      notification.success({
        message: e?.success ? 'Success' : 'Error',
        description: e?.message,
        placement: 'topRight',
        duration: 3, // Reduced duration to 3 seconds
      })
    })
  }

  
  subscribeToIssue(issueSlug) {
    if (!this.echo) {
      console.error('Echo is not initialized')
      return
    }

    const channel = this.echo.channel(`App.Models.Eloquent.Issue.${issueSlug}`)

    channel.listen('FastTrackingCompletedEvent', (e) => {
      this.handleFastTrackingCompletedEvent(e)
    })

    channel.listen('DeploymentNotificationEvent', (e) => {
      this.handleDeploymentNotificationEvent(e)
    })

    channel.listen('SurveyCompletedEvent', (e) => {
      this.handleSurveyCompletedEvent(e)
    })

    channel.listen('DocumentAuthorizationEvent', (e) => {
      this.handleDocumentAuthorizationEvent(e)
    })
    //
    // channel.listen('RPLAuthorizationEvent', (e) => {
    //   this.handleRPLAuthorizationEvent(e)
    // })

    // You can add more event listeners here
  }

  handleDeploymentNotificationEvent() {
    window.location.reload()
  }

  handleFastTrackingCompletedEvent(e) {
    const issuesStore = useIssuesStore()
    const currentIssue = issuesStore.currentIssue

    if (e?.issueSlug === currentIssue?.slug) {
      // Create a promise for the notification
      const notificationPromise = new Promise((resolve) => {
        notification.success({
          message: e?.success ? 'Success' : 'Error',
          description: e?.message,
          placement: 'topRight',
          duration: 3, // Reduced duration to 3 seconds
          onClose: resolve, // Resolve the promise when the notification is closed
        })
      })

      // Notify all registered listeners
      this.notifyListeners('FastTrackingCompletedEvent', e)

      notificationPromise.then(() => {
        if (e?.success)
          location.reload()
      })
    }
  }

  handleSurveyCompletedEvent(e) {
    const issuesStore = useIssuesStore()
    const currentIssue = issuesStore.currentIssue

    if (e?.issueSlug === currentIssue?.slug) {
      if (e?.surveyType === 'dissatisfaction-survey' || e?.surveyType === 'case-monitor-survey') {
        useIssuesStore().currentIssue.status.id = e?.statusId
        notification.success({
          message: e?.success ? 'Success' : 'Error',
          description: e?.statusId === 620 ? 'Your case status is now LOCKED.' : 'Your case status is unchanged.',
          placement: 'topRight',
          duration: 3, // Reduced duration to 3 seconds
        })

        router.push({ name: 'issues.details', params: { slug: currentIssue?.slug } })
      }
    }
  }

  handleDocumentAuthorizationEvent(e) {
    const issuesStore = useIssuesStore()
    const currentIssue = issuesStore.currentIssue

    if (e?.issueSlug === currentIssue?.slug) {
      useIssuesStore().currentIssue.authorization_status = e?.authorization_status
      useIssuesStore().currentIssue.progress = e?.progress
      useIssuesStore().currentIssue.status.id = e?.status_id
      useIssuesStore().reloadCurrentIssue()

      // Create a promise for the notification
      const notificationPromise = new Promise((resolve) => {
        notification.success({
          message: e?.success ? 'Success' : 'Error',
          description: e?.message,
          placement: 'topRight',
          duration: 3, // Reduced duration to 3 seconds
          onClose: resolve, // Resolve the promise when the notification is closed
        })
      })

      // Notify all registered listeners
      this.notifyListeners('DocumentAuthorizationEvent', e)

      // Check if we're on a specific route where we want to reload
      const reloadRoutes = ['issues.details', 'issues.edit'] // Add the names of the routes where you want to reload
      if (reloadRoutes.includes(router.currentRoute.value.name)) {
        // Wait for the notification to be displayed before reloading
        notificationPromise.then(() => {
          location.reload()
        })
      }
    }
  }

  handleRPLAuthorizationEvent(e) {
    const issuesStore = useIssuesStore()
    const evaluateStore = useEvaluateStore()
    const currentIssue = issuesStore.currentIssue
    const evaluation = evaluateStore.evaluations[evaluateStore.evaluationIndex]

    // route navigation
    if (e?.issueSlug === currentIssue?.slug && e?.evaluation_id === evaluation?.id) {
      notification.success({
        message: e?.success ? 'Success' : 'Error',
        description: e?.message,
        placement: 'topRight',
        duration: 60,
      })

      router.push({ name: 'issues.details', params: { slug: currentIssue.slug } })
    }
  }

  // fireGAEvent(eventName, params) {
  //   if (window.gtag)
  //     window.gtag('event', eventName, params)
  // }

  unsubscribeFromIssue(issueSlug) {
    if (this.echo) {
      const channel = this.echo.channel(`App.Models.Eloquent.Issue.${issueSlug}`)
      channel.stopListening('FastTrackingCompletedEvent') // Stop listening to the specific event
    }
  }

  addListener(event, callback) {
    if (!this.listeners.has(event))
      this.listeners.set(event, new Set())

    this.listeners.get(event).add(callback)
  }

  removeListener(event, callback) {
    if (this.listeners.has(event))
      this.listeners.get(event).delete(callback)
  }

  notifyListeners(event, data) {
    if (this.listeners.has(event))
      this.listeners.get(event).forEach(callback => callback(data))
  }
}

export const webSocketManager = new WebSocketManager()
