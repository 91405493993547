import { defineStore } from 'pinia';
import { notification } from 'ant-design-vue';
import IssueService from '../util/services/issues';
import InsuranceService from '../util/services/insurance';
import DisputeService from '../util/services/dispute.js';
import DocumentsService from '../util/services/documents.js';
import PeopleService from "../util/services/people.js";
import local from "../util/local-util.js";
import ProvidersService from "../util/services/providers.js";
import InsuranceSettingsService from '../util/services/insuranceSettings.js';
import router from './../router/index';
import MetricsService from './../util/services/metrics';
import LODSettingsService from './../util/services/lodSettings';
import AuthorizationService from '@/modules/core/services/authorization';
import FastTrackService from '@/modules/core/services/fastTrack';
import {computed} from "vue";
import CaseManagementService from "@/util/services/caseManagement.js";

export const useIssuesStore = defineStore('issues', {
  state: () => ({
    loading: true,
    issues: [],
    currentIssueId: null,
    currentIssue: null,
    success: false,
    errors: [],
    message: null,
    submitting: false,
    partial: true,
    disabled: false,
    highlightedCells: null,
  }),

  getters: {
    isMBPRComplete: computed(() => state.currentIssue?.medicare_price || state.currentIssue?.cost_to_charge_ratio),
  },

  actions: {
    setCurrentIssue(id) {
      this.currentIssueId = id;
      local.set('issueId', id);

      this.currentIssue = null;
      this.reloadCurrentIssue();
    },
    reloadCurrentIssue() {
      if(!this.issues.length || !this.currentIssueId) {
        this.currentIssue = null;
        this.currentIssueId = null;
      } else {
        this.currentIssue = this.issues.find((i) => i.id == this.currentIssueId);
        if (this.currentIssue?.authorization_status === 'completed' || this.currentIssue?.status?.id === 620) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      }
    },
    updateIssue(issue){
      const index = this.issues.indexOf(this.findBy('id', issue?.id));

      this.issues[index].notifications = issue.notifications;
      this.issues[index].progress = issue.progress;
      this.issues[index].status = issue.status;
      this.issues[index].received_response_from_provider = issue.received_response_from_provider;

      this.reloadCurrentIssue();
    },
    updatePayment(issue) {
      const index = this.issues.indexOf(this.findBy('id', issue?.id));

      this.issues[index].progress = issue.progress;
      this.issues[index].status = issue.status;
      this.issues[index].payment_status = issue.payment_status;

      this.reloadCurrentIssue();
    },
    updateBill(bill){
      const index = this.issues.indexOf(this.findBy('id', this.currentIssueId));

      this.issues[index].bill = {
        ...this.issues[index].bill,
        ...bill
      };

      this.reloadCurrentIssue();
    },
    updateInsurance(insurance){
      const index = this.issues.indexOf(this.findBy('id', this.currentIssueId));

      this.issues[index].bill.insurance = {
        ...this.issues[index].bill?.insurance,
        ...insurance
      };

      this.reloadCurrentIssue();
    },
    /**
     * @deprecated ?!
     * 
     * @param {*} issues 
     */
    setIssues(issues) {
      this.issues = issues;
      this.loading = false;
      this.success = true;
      this.errors = [];
      this.message = null;
    },
    async find(id) {
      // if (this.success) {
      //   this.findBy('id', id);
      //
      //   return
      // }
      
      const issueResponse = await IssueService.find(id);
      if (issueResponse.success) {
        const existingIssue = this.issues.find(i => i.id === issueResponse?.data?.id);
        
        if (!existingIssue) {
          this.issues.push(issueResponse.data);
          this.setCurrentIssue(issueResponse.data?.id);
        }else {
          const index = this.issues.indexOf(this.findBy('id', issueResponse.data?.id));
          this.issues[index] = {...this.currentIssue, ...issueResponse.data};

          this.setCurrentIssue(issueResponse.data?.id);
        }
      }
    },
    findBy(key, value) {
      return this.issues?.find((i) => i[key] == value);
    },
    async fetchIssues() {
      try {
        const response = await IssueService.all();
        if (response.success) {
          if (this.issues.length) {
            response.data.map((item) => {
              const existingIndex = this.issues.findIndex((existingItem) => existingItem.id === item.id);
              if (existingIndex !== -1) {
                this.issues[existingIndex] = Object.assign({}, item, this.issues[existingIndex]);
              } else {
                this.issues = this.issues.concat([item]);
              }
            });
          } else {
            this.issues = response.data;
          }
          
          this.loading = false;
          this.success = true;
          this.errors = [];
          this.message = null;
          this.partial = false;

          // ToDo: check if this part needs to be removed from here
          // if (this.issues.length && !this.currentIssue) {
          //   this.setCurrentIssue(local.get('issueId') || this.issues[0].id);
          // }
        }
      } catch (error) {
        console.error('Error fetching issues:', error);

        this.loading = false;
        this.success = false;
        this.errors = error.errors;
        this.message = error.message;
      }
    },
    async findBySlug(slug) {
      if (this.currentIssue?.bill?.patient && slug === this.currentIssue?.slug) {
        return;
      }

      const issueResponse = await IssueService.bySlug(slug);

      if (issueResponse.success) {
        const existingIssue = this.issues.find(i => i.id === issueResponse.data?.id);
        
        if (!existingIssue) {
          this.issues.push(issueResponse.data);
          this.setCurrentIssue(issueResponse.data.id);
        } else {
          const index = this.issues.indexOf(this.findBy('id', issueResponse.data?.id));
          this.issues[index] = {...this.currentIssue, ...issueResponse.data};
          this.setCurrentIssue(issueResponse.data?.id);
        }
      } else {
        router.replace({name: 'dashboard'});
        notification.error({
          placement: 'topRight',
          message: 'Error',
          description: "Failed to fetch issue",
        });
      }
    },

    async loadMetrics() {
      try {
        const currentIssueId = this.currentIssueId;

        const response = await MetricsService.getMetrics(currentIssueId);

        if (response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          if (index !== -1) {
            this.issues[index] = {
              ...this.issues[index],
              ...response.data,
              bill: {
                ...this.issues[index].bill,
                ...response.data?.bill,
                insurance: {
                    ...this.issues[index].bill?.insurance,
                    ...response.data?.bill?.insurance,
                    eob: {
                        ...this.issues[index].bill?.insurance?.eob,
                        ...response.data?.bill?.insurance?.eob
                    }
                },
                gfe: {
                  ...this.issues[index].gfe,
                  ...response.data?.gfe
                }
              },
            };

            this.reloadCurrentIssue();
          }
        }
        // ToDo: check error response
      } catch (error) {
        console.error('Error fetching metrics:', error);
        throw error;
      }
    },

    async loadBaseData() {
      try {
        if (this.currentIssue && !!this.currentIssue.bill) {
          return;
        }

        const response = await IssueService.find(this.currentIssue?.id);

        if (response.success) {
          const index = this.issues.indexOf(this.findBy('id', response.data?.id));

          this.issues[index] = {...this.currentIssue, ...response.data};
          this.reloadCurrentIssue();
        }
        // ToDo: check error response
      } catch (error) {
        console.error('Error fetching issues:', error);
        throw error;
      }
    },

    async loadBilledPerson() {
      try {
        const currentIssueId = this.currentIssueId;

        if (
          !this.currentIssue ||
          !this.currentIssue.bill ||
          !this.currentIssue.bill?.billed_id ||
          this.currentIssue.billedPerson
        )
          return;

        const response = await PeopleService.find(this.currentIssue.bill.billed_id);

        if (response.success) {

          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          if (index !== -1) {
            this.issues[index].billedPerson = response.data;
            
            if(this.issues[index].bill.billed_id == this.issues[index].bill.patient_id) {
              this.issues[index].patient = response.data;
            }

            this.reloadCurrentIssue();
          }
        }

        // ToDo: check error response
      } catch (error) {
        console.error('Error fetching loadBill:', error);
        throw error;
      }
    },

    async loadPatient() {
      try {
        const currentIssueId = this.currentIssueId;
        
        if (
          !this.currentIssue ||
          !this.currentIssue.bill ||
          !this.currentIssue.bill?.patient_id ||
          this.currentIssue.patient
        )
          return;

        const response = await PeopleService.find(this.currentIssue.bill.patient_id);

        if (response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          if (index !== -1) {
            this.issues[index].patient = response.data;

            this.reloadCurrentIssue();
          }
        }
        // ToDo: check error response
      } catch (error) {
        console.error('Error fetching loadPatient:', error);
        throw error;
      }
    },

    async loadProvider() {
      try {
        const currentIssueId = this.currentIssueId;

        if (
            !this.currentIssue ||
            !this.currentIssue.bill ||
            !this.currentIssue.bill?.provider_id ||
            this.currentIssue.provider
        )
          return;

        const response = await ProvidersService.find(this.currentIssue.bill.provider_id);

        if (response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          if (index !== -1) {
            this.issues[index].provider = response.data;

            this.reloadCurrentIssue();
          }
        }
        // ToDo: check error response
      } catch (error) {
        console.error('Error fetching loadPatient:', error);
        throw error;
      }
    },

    async loadDocuments() {
      try {
        const currentIssueId = this.currentIssueId;

        if (this.currentIssue && !!this.currentIssue.documents)
          return;

        const response = await DocumentsService.allFromIssue(currentIssueId);

        if (response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          if (index !== -1) {
            this.issues[index].documents = response.data;

            this.reloadCurrentIssue();
          }
        }
        // ToDo: check error response
      } catch (error) {
        console.error('Error fetching loadPatient:', error);
        throw error;
      }
    },

    async loadInsurance() {
      try {
        const currentIssueId = this.currentIssueId;

        if (
            !this.currentIssue ||
            !this.currentIssue.bill ||
            !this.currentIssue.bill?.insurance_id ||
            this.currentIssue.bill.insurance
        ) {
          return;
        }

        const response = await InsuranceService.find(this.currentIssue.bill.insurance_id);

        if (response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          if (index !== -1) {
            this.issues[index].bill.insurance = response.data;

            this.reloadCurrentIssue();
          }
        }
      } catch (error) {
        console.error('Error fetching loadInsurance:', error);
        throw error;
      }
    },

    async loadInsurer() {
      try {
        const currentIssueId = this.currentIssueId;

        const response = await InsuranceService.insurer(currentIssueId);

        if (response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          if (index !== -1) {
            this.issues[index].bill.insurer = response.data;

            this.reloadCurrentIssue();
          }
        }
      } catch (error) {
        console.error('Error fetching loadInsurer:', error);
        throw error;
      }
    },

    async loadGFE() {
      try {
        const currentIssueId = this.currentIssueId;

        if (
          !this.currentIssue ||
          !this.currentIssue.bill
        ) {
          return;
        }

        const response = await DisputeService.findGFE(currentIssueId);

        if (response.success) {
          const index = this.issues.indexOf(this.findBy("id", this.currentIssueId));

          if (index !== -1) {
            this.issues[index].bill.gfe = response.data;

            this.reloadCurrentIssue();
          }
        }
      } catch (error) {
        console.error("Error fetching loadGFE:", error);
        throw error;
      }
    },

    async loadLODSettings() {
      try {
        const currentIssueId = this.currentIssueId;

        const response = await LODSettingsService.find(currentIssueId);

        if (response.success) {
          const index = this.issues.indexOf(this.findBy("id", this.currentIssueId));

          if (index !== -1) {
            this.issues[index] = response.data.issue;
            this.issues[index].lod = response.data.lod_settings;

            this.reloadCurrentIssue();
          }
        }
      } catch (error) {
        console.error("Error finding lod settings:", error);
        throw error;
      }
    },

    async loadInsuranceLetterSettings() {
      try{
        const currentIssueId = this.currentIssueId;

        const response = await InsuranceSettingsService.find(currentIssueId);

        if (response.success) {
          const index = this.issues.indexOf(this.findBy("id", this.currentIssueId));

          if (index !== -1) {
            this.issues[index] = {
              ...this.issues[index],
              ...response.data.issue,
              insuranceSettings : response.data.insurance_settings,
            }
            // this.issues[index].insuranceSettings = response.data.insurance_settings;

            this.reloadCurrentIssue();
          }
        }
      } catch (error) {
        console.error("Error finding lod settings:", error);
        throw error;
      }
    },

    
    async sendPreviewLOD(issueId) {
      try {
        const response = await IssueService.sendPreviewLOD(issueId);

        if (response.success) {
          const index = this.issues.indexOf(this.findBy('id', issueId));
          this.issues[index].is_preview_sent = 1;
          this.reloadCurrentIssue();
        }
      } catch (error) {
        console.error('Error sending preview LOD:', error);
        throw error;
      }
    },

    
    async saveNewCase(data) {
      try {
        const response = await IssueService.new(data);

        if (response.success) {
          local.append('newCase', {
            id: response.data?.id,
            slug: response.data.slug
          });

          this.issues.push(response.data);
          this.setCurrentIssue(response.data?.id);
        }

        const index = this.issues.indexOf(this.findBy('id', response.data?.id));

        if (index !== -1) {
          this.issues[index] = response.data;
        }

        this.reloadCurrentIssue();
      } catch (error) {
        console.error('Error fetching saveNewCase:', error);
        throw error;
      }
    },

    async updateNewCase(data) {
      try {
        const response = await IssueService.update(
          router.currentRoute.value.params?.slug || local.get('newCase').slug,
          data
        );
    
        if (response.success) {
          this.issues.push(response.data);
          this.setCurrentIssue(response?.data?.id);
        }
    
        const index = this.issues.indexOf(this.findBy("id", response?.data?.id));
    
        if (index !== -1) {
          this.issues[index] = response.data;
        }
    
        this.reloadCurrentIssue();
      } catch (error) {
        console.error("Error fetching updateNewCase:", error);
        throw error;
      }
    },
    
    async saveBilledPerson(data) {
      this.errors = [];
      try {
        this.submitting = true;
        const method = this.currentIssue && this.currentIssue.billedPerson ? 'PATCH' : 'POST';

        const currentIssue = this.currentIssueId || 0;

        const response = await IssueService.saveBilledPerson(
          currentIssue,
          data, 
          method
        );

        if(response.success) {
          if(currentIssue === 0) {

            local.append('newCase', {
              id: response.data?.id,
              slug: response.data.slug
            });

            this.issues.push(response.data);
            this.setCurrentIssue(response.data?.id);
          }

          //console.log(this.issues);

          const index = this.issues.indexOf(this.findBy('id', response.data?.id));

          if(response.data.bill === null) {
            this.issues[index].status = response.data.status;
          } else {
            this.issues[index].billedPerson = response.data.bill.billedPerson;
            this.issues[index].bill = response.data.bill;
            this.issues[index].status = response.data.status;
            this.issues[index].progress = response.data.progress;
          if (this.issues[index]?.bill?.involvement === 2)
            this.issues[index].patient = response.data.bill.billedPerson
          }

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }
        
        this.success = response.success;
      } catch (error) {
        // ToDo: fill errors
        if (data.hasOwnProperty('person.addresses')) {
          this.errors.ownprop = true;
        }
        this.success = false;
      }

      this.submitting = false
    },

    async saveProblemsLOD(issueId) {
      try {
          this.submitting = true;

          const response = await IssueService.problems(
            issueId
          );

          if(response.success) {
            const index = this.issues.indexOf(this.findBy('id', issueId));
            this.issues[index] = {
              ...this.issues[index],
              ...response.data.issue
            };
            this.issues[index].lodProblems = response.data.problems;

            this.reloadCurrentIssue();
          } else { 
            if (response.errors) {
              this.errors = response.errors;
              this.errors.ownprop = true;
            }
            notification.error({
              message: 'Error',
              description: response.message,
              placement: 'topRight',
            })
          }
          
          this.success = response.success;
      } catch (error) {
        console.error('Error sending preview LOD:', error);
        throw error;
      }

      this.submitting = false
    },

    async savePatient(data) {
      try {
        this.submitting = true;
        const method = this.currentIssue && this.currentIssue.patient ? 'PATCH' : 'POST';

        const response = await IssueService.savePatient(
          this.currentIssueId,
          data, 
          method
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', response.data?.id));
          this.issues[index].patient = response.data.bill.patient;
          this.issues[index].progress = response.data.progress;

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }
        
        this.success = response.success;
      } catch (error) {
        // ToDo: fill errors
        if (data.hasOwnProperty('person.addresses')) {
          this.errors.ownprop = true;
        }
        this.success = false;
      }

      this.submitting = false
    },
  
    async saveProviderDetails(data, providerData) {

      try {
        this.submitting = true;
        const method = this.currentIssue && this.currentIssue.provider ? 'PATCH' : 'POST';

        const currentIssueId = this.currentIssueId;

        const response = await IssueService.saveProviderDetails(
          currentIssueId,
          data, 
          method
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', this.currentIssueId));
          this.issues[index].bill.provider= response.data.bill.provider;
          this.issues[index].title = response.data.title;
          this.issues[index].status = response.data.status;
          this.issues[index].progress = response.data.progress;

          // todo: remove this when the backend is fixed
          // todo: remove the providerData from the function also
          // if (providerData)
          //   this.issues[index].provider.newData = providerData;

          local.remove('newCase');
          this.reloadCurrentIssue();

        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }
        
        this.success = response.success;
      } catch (error) {
        // ToDo: fill errors
        if (data.hasOwnProperty('person.addresses')) {
          this.errors.ownprop = true;
        }
        this.success = false;
      }

      this.submitting = false
    },

    async processFastTrack(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId || 0;

        const response = await FastTrackService.process(
          currentIssueId, 
          data
        );
  
        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = response.data

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }
        
        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }
  
      this.submitting = false
    },

    async submitFastTrack() {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId || 0;

        const response = await FastTrackService.submit(
          currentIssueId, 
        );
  
        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = response.data

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }
        
        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }
  
      this.submitting = false
    },

    async saveMedicalBillDetails(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await IssueService.saveMedicalBillDetails(
          currentIssueId, 
          data
        );
  
        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          this.issues[index].bill = {
            ...this.issues[index].bill,
            ...response.data.bill
          };

          if (this.issues[index]?.bill?.provider?.provider_name)
            this.issues[index].title = `${this.issues[index]?.bill?.provider?.provider_name}-${data.get('title_date')}`;
          else
            console.error('One of the properties is undefined or null');

          this.issues[index].progress = response.data.progress

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }
        
        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }
  
      this.submitting = false
    },
  
    async saveLookAtBill(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId || 0;

        const response = await IssueService.saveLookAtBill(
          currentIssueId, 
          data
        );
  
        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index].bill = {
            ...this.issues[index].bill,
            ...response.data.bill
          };

          this.issues[index].progress = response.data.progress

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }
        
        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }
  
      this.submitting = false
    },

    async saveInsurance(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;
        

        const response = await InsuranceService.saveInsurance(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = response.data.issue

          this.issues[index].bill.insurance = response.data.insurance
          this.issues[index].bill.insurance_id = response.data?.insurance?.id ?? null;

          if(response.data?.insurance.is_insurance_covering_service) 
            this.issues[index].is_insurance_covering_service = parseInt(response.data?.insurance.is_insurance_covering_service ,10);
          else
            this.issues[index].is_insurance_covering_service = 0


          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },

    async saveContactInfo(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await InsuranceService.saveContactInfo(
            currentIssueId,
            data
          );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = response.data.issue

          this.issues[index].bill.insurance = {
            ...this.issues[index].bill.insurance,
            ...response.data.insurance
          };


          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },

    async savePolicyDetails(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await InsuranceService.savePolicyDetails(
          currentIssueId,
          data
        );
          
        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = response.data.issue

          this.issues[index].bill.insurance = {
            ...this.issues[index].bill.insurance,
            ...response.data.insurance
          };


          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },

    async saveInsurancePayments(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await InsuranceService.saveInsurancePayments(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = {
            ...this.issues[index],
            ...response.data
          };

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },

    async saveEOBDetails(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await InsuranceService.saveEOBDetails(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = response.data.issue
          this.issues[index].bill.insurance = {}

          this.issues[index].bill.insurance.eob = {
            ...this.issues[index].bill.insurance.eob,
            ...response.data.eob
          };

          
          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },


    async saveProviderAndServices(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await DisputeService.saveProviderAndServices(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = {
            ...this.issues[index],
            ...response.data
          };

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },

    async saveDisputeDetails(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await DisputeService.saveDisputeDetails(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = {
            ...this.issues[index],
            ...response.data
          };

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },


    async saveDisputeDetailsAgreements(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await DisputeService.saveDisputeDetailsAgreements(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = {
            ...this.issues[index],
            ...response.data
          };

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }
        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },

    async saveGFE(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await DisputeService.saveGFE(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = response.data.issue

          if(Array.isArray(response.data.gfe)) {
            this.issues[index].bill.gfe = null
          } else {
            this.issues[index].bill.gfe = {
              ...this.issues[index]?.bill?.gfe,
              ...response.data.gfe
            };
          }


          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },

    async saveGFEDetails(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await DisputeService.saveGFEDetails(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = response.data.issue

          this.issues[index].bill.gfe = {
            ...this.issues[index]?.bill?.gfe,
            ...response.data.gfe
          };


          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },

    async saveDisclosure(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await DisputeService.saveDisclosure(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = {
            ...this.issues[index],
            ...response.data
          };
          
          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },

    async saveStateSpecific(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await DisputeService.saveStateSpecific(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = {
            ...this.issues[index],
            ...response.data.issue
          };


          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },

    async saveLODSettings(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await LODSettingsService.save(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          this.issues[index] = response.data.issue
          this.issues[index].lod = {
            ...this.issues[index]?.lod,
            ...response.data.lod_settings
          };

           

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },

    async saveInsuranceSettings(data) {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await InsuranceSettingsService.save(
          currentIssueId,
          data
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          if (index !== -1) {
            this.issues[index] = {
            ...this.issues[index], 
            ...response.data.issue,
            }

            this.issues[index].insuranceSettings = {
            ...this.issues[index]?.insuranceSettings,
            ...response.data.insurance_settings
            };
    
          this.reloadCurrentIssue();
          }
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = true;
      } catch (error) {
        // router.push({name: 'error'});
        // this.success = false;
      }

      this.submitting = false

    },



    async saveNoInsuranceLetterCheck(data) {
      try {
        this.submitting = true

        const currentIssueId = this.currentIssueId

        const response = await InsuranceSettingsService.saveNoInsuranceLetterCheck(
          currentIssueId,
          data
        );

        if (response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));
          if (index !== -1) {
            this.issues[index] = response.data.issue

            this.reloadCurrentIssue();
            this.success = true;
          }
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }
        
      } catch (error) {
        // router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false

    },

    async authorizeCase() {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await AuthorizationService.lod(
          currentIssueId,
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          this.issues[index] = response.data.issue

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },
    async completeCase() {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await CaseManagementService.complete(
            currentIssueId,
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          this.issues[index] = response.data

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },
    async stopCase() {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await CaseManagementService.stop(
            currentIssueId,
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          this.issues[index] = response.data

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },
    async lockCase() {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await CaseManagementService.lock(
            currentIssueId,
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          this.issues[index] = response.data

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },
    async disableCase() {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await CaseManagementService.disable(
            currentIssueId,
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          this.issues[index] = response.data

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },
    async reopenCase() {
      try {
        this.submitting = true;

        const currentIssueId = this.currentIssueId;

        const response = await CaseManagementService.reopen(
            currentIssueId,
        );

        if(response.success) {
          const index = this.issues.indexOf(this.findBy('id', currentIssueId));

          this.issues[index] = response.data

          this.reloadCurrentIssue();
        } else {
          if (response.errors) {
            this.errors = response.errors;
            this.errors.ownprop = true;
          }

          notification.error({
            message: 'Error',
            description: response.message,
            placement: 'topRight',
          })
        }

        this.success = response.success;
      } catch (error) {
        router.push({name: 'error'});
        this.success = false;
      }

      this.submitting = false
    },
  },
});