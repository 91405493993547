import Axios from 'axios'
import { notification } from 'ant-design-vue'
import { getTokenSetLocal } from './localTokenAccess.js'
import local from './local-util'

const baseURL = import.meta.env.VITE_URL
const CLIENT_VERSION = import.meta.env.VITE_APP_VERSION // Define your client version

// Singleton to prevent multiple version mismatch notifications
let versionMismatchNotificationShown = false

export const $axios = Axios.create({
  baseURL,
})

// Add a response interceptor
// $axios.interceptors.response.use(
//   (response) => {
//     // Check the version in the response headers
//     const apiVersion = response.headers['x-client-version']
//
//     // Ensure only one notification is shown
//     if (apiVersion && apiVersion !== CLIENT_VERSION && !versionMismatchNotificationShown) {
//       // Set the flag to prevent multiple notifications
//       versionMismatchNotificationShown = true
//
//       notification.warn({
//         message: 'Page Refresh',
//         description: 'Your page is reloading to update.',
//         placement: 'bottom',
//         duration: 3, // Show for 3 seconds
//         onClose: () => {
//           // Reset the flag when notification closes
//           versionMismatchNotificationShown = false
//         },
//       })
//
//       // Use a shorter timeout and be explicit about reloading
//       setTimeout(() => {
//         window.location.reload()
//       }, 3000)
//     }
//
//     // Always return the response to prevent null data
//     return response
//   },
//   (error) => {
//     // Handle response errors in the interceptor
//     return Promise.reject(error)
//   },
// )

async function api(payload) {
  const { url, method, headers, body, token, formData } = payload
  const { accessToken } = getTokenSetLocal()
  const bearerToken = typeof token !== 'undefined' ? token : accessToken
  const contentType = typeof formData !== 'undefined' && formData !== null ? 'multipart/form-data' : 'application/json'
  const defaultHeaders = {
    'Content-Type': contentType,
    'Accept': 'application/json',
    'Authorization': `bearer ${bearerToken}`,
    'Access-Control-Allow-Origin': '*',
  }
  const defaultData = {}

  try {
    const response = await $axios({
      method: method || 'GET',
      url,
      headers: { ...defaultHeaders, ...headers },
      data: body || formData || defaultData,
    })

    return {
      data: response.data,
      headers: response.headers, // Include headers in the return object
    }
  }
  catch (error) {
    if (error.response?.status === 401) {
      notification.error({
        message: 'Error',
        description: error.response.data.message,
        placement: 'topRight',
      })
      local.purge()
      window.location.reload()
    }
    throw error
  }
}

export default api
