export const options = {
  0: "No",
  1: "Yes",
  2: "No",
  3: "I'm not sure",
  4: "Not applicable",
};

export const involvement = {
  1: "Billed Person",
  2: "Billed Person and Patient",
  3: "Neither",
};

export const insurers = [
  "Blue Cross and Blue Shield of Alabama",
  "Premera Blue Cross and Blue Shield of Alaska",
  "Blue Cross Blue Shield of Arizona",
  "Arkansas Blue Cross and Blue Shield",
  "Anthem Blue Cross (CA)",
  "Blue Shield of California",
  "Anthem Blue Cross and Blue Shield Colorado",
  "Anthem Blue Cross and Blue Shield Connecticut",
  "Highmark Blue Cross Blue Shield Delaware",
  "CareFirst BlueCross BlueShield (D.C.)",
  "Florida Blue",
  "Blue Cross Blue Shield of Puerto Rico (FL)",
  "Blue Cross Blue Shield of Georgia",
  "Blue Cross and Blue Shield of Hawaii",
  "Blue Cross of Idaho",
  "Regence BlueShield of Idaho",
  "Blue Cross and Blue Shield of Illinois",
  "Anthem Blue Cross and Blue Shield Indiana",
  "Wellmark Blue Cross and Blue Shield (IA)",
  "Blue Cross and Blue Shield of Kansas",
  "Anthem Blue Cross and Blue Shield Kentucky",
  "Blue Cross and Blue Shield of Louisiana",
  "Anthem Blue Cross and Blue Shield Maine",
  "CareFirst BlueCross BlueShield (MD)",
  "Blue Cross and Blue Shield of Massachusetts",
  "Blue Cross Blue Shield of Michigan",
  "Blue Cross and Blue Shield of Minnesota",
  "Blue Cross & Blue Shield of Mississippi",
  "Anthem Blue Cross and Blue Shield Missouri",
  "Blue Cross and Blue Shield of Kansas City",
  "Blue Cross and Blue Shield of Montana",
  "Blue Cross and Blue Shield of Nebraska",
  "Anthem Blue Cross and Blue Shield Nevada",
  "Anthem Blue Cross and Blue Shield New Hampshire",
  "Horizon Blue Cross and Blue Shield of New Jersey",
  "Blue Cross and Blue Shield of New Mexico",
  "Empire Blue Cross Blue Shield (NY)",
  "Highmark Blue Cross Blue Shield of Western New York",
  "Highmark Blue Shield of Northeastern New York",
  "Excellus BlueCross BlueShield (NY)",
  "Blue Cross and Blue Shield of North Carolina",
  "Blue Cross Blue Shield of North Dakota",
  "Anthem Blue Cross and Blue Shield Ohio",
  "Blue Cross and Blue Shield of Oklahoma",
  "Regence BlueCross BlueShield (OR)",
  "Capital Blue Cross (PA)",
  "Highmark Blue Shield (PA)",
  "Highmark Blue Cross Blue Shield (PA)",
  "Independence Blue Cross (PA)",
  "Blue Cross & Blue Shield of Rhode Island",
  "Blue Cross and Blue Shield of South Carolina",
  "Wellmark Blue Cross and Blue Shield (SD)",
  "BlueCross BlueShield of Tennessee",
  "Blue Cross and Blue Shield of Texas",
  "Regence BlueCross BlueShield (UT)",
  "Blue Cross and Blue Shield of Vermont",
  "Anthem Blue Cross and Blue Shield Virginia",
  "CareFirst BlueCross BlueShield (VA)",
  "Premera Blue Cross (WA)",
  "Regence BlueShield (WA)",
  "Highmark Blue Cross Blue Shield West Virginia",
  "Anthem Blue Cross and Blue Shield Wisconsin",
  "Blue Cross Blue Shield of Wyoming",
  "Other Blue Cross Blue Shield",
];

export const metricsConcerns = [
  "Balance Due from Patient exceeds Total Charges minus Total Payments and Discounts",
  "Total Charges minus Total Payments and Discounts exceeds Balance Due from Patient",
  "Balance Due from Patient exceeds Total Charges minus Owed by Patient Under Insurance and Discounts",
  "Total Charges minus Owed by Patient Under Insurance and Discounts exceeds Balance Due from Patient",
];

// export const metricsDiffers = [
//   {
//     key: "BD61",
//     highlight: ["B15", "F15", "B42", "D42"],
//     text: "Total Charges after Adjustments (Medical Bill) exceeds Total Charges (GF Estimate)",
//   },
//   {
//     key: "BD64",
//     highlight: ["B15", "H15", "B42", "F42"],
//     text: "Total Charges after Adjustments (Medical Bill) exceeds Total Charges (Patient Agreement)",
//   },
//   {
//     key: "BD65",
//     highlight: ["B30", "B28", "H26", "B46", "F42", "H44"],
//     text: "Balance Due from Patient (Medical Bill) exceeds Balance Due from Patient (Patient Agreement)",
//   },
//   {
//     key: "B67",
//     highlight: ["B30", "B28", "D30", "B28"],
//     text: "Owed by Patient (Medical Bill) > Owed by Patient (EOB) [before patient payments]",
//   },
//   {
//     key: "BD70",
//     highlight: ["B28", "J28", "B44", "H44"],
//     text: "Patient Payments (Medical Bill) < Patient Payments (customer)",
//   }
// ];

export const metricsOvercharges = [
  {
    key: "LM61",
    highlight: ["D42", "B42", "F15", "B15"],
    text: "The Medical Bill Total Charges (after adjustments) exceed the Provider's estimate for the Patient.",
  },
  {
    key: "LM64",
    highlight: ["F42", "B42", "H15", "B15"],
    text: "The Medical Bill Total Charges (after adjustments) exceed the agreement between Provider and Patient.",
  },
  {
    key: "LM65",
    highlight: ["H44", "F42", "B46", "H26", "B30", "J28"],
    text: "The Medical Bill Balance Due From Patient (after adjustments) exceeds the agreement between Provider and Patient.",
  },
  {
    key: "L67",
    highlight: ["D28", "B30", "D30", "B28"],
    text: "The Medical Bill Owed by Patient (before Patient payments made) exceeds that of the Insurance EOB.",
  },
  {
    key: "LM70",
    highlight: ["H44", "B44", "J28", "B28"],
    text: "The Medical Bill does not credit all the payments you said the patient has made.",
  },
];


export const gfeIncludes = [
  "Patient name and date of birth",
  "A list of items or services expected to be provided that are reasonably expected to be furnished in conjunction with the primary item or service as part of the period of care",
  "Applicable diagnosis codes",
  "Expected service codes",
  "Expected charges associated with each listed item or service",
  "Provider information: name, National Provider Identifiers, and Tax Identification Number, and the state(s) and office or facility location(s) where the items or services are expected to be furnished",
  "A disclaimer that the GFE is not a contract and does not require the uninsured (or self-pay) individual to obtain the items or services from any of the co-providers or co-facilities identified in the GFE",
];

export const gfeMissing = [
  "Patient name and date of birth",
  "Description of primary item or services in clear and understandable language",
  "A list of items or services, grouped by provider or facility, expected to be provided for the primary item or service, as well as other items or services expected to be furnished in connection with the primary item or service",
  "Applicable diagnosis codes",
  "Expected service codes",
  "Expected charges associated with each listed item or service",
  "Provider information: name, National Provider Identifiers, and Tax Identification Number, and the state(s) and office or facility location(s) where the items or services are expected to be furnished",
  "List of items or services that the convening provider or convening facility anticipates will require separate scheduling and that are expected to occur before or following the expected period of care for the primary item or service.",
  "A disclaimer that there may be additional items or services the convening provider or convening facility recommends as part of the course of care that must be scheduled or requested separately and are not reflected in the good faith estimate",
  "A disclaimer that informs the individual that the information provided in the good faith estimate is only an estimate regarding items or services reasonably expected to be furnished at the time the good faith estimate is issued and that actual items, services or charges may differ",
  "A disclaimer that informs the individual of the right to initiate the patient-provider dispute resolution process if the actual billed charges are substantially in excess of the expected charges (with an explanation of where to find information on how to initiate the dispute resolution and a statement that initiating the dispute resolution process will not affect the quality of health care received)",
  "A disclaimer that the good faith estimate is not a contract and does not require the patient to obtain the items or services",
];

export const providerTypes = [
  {
    category: 'Most Common Provider Types',
    options: [
      { key: '1', type: 'doctor', payloadType: 1, value: 'Physician/Doctor/Professional (e.g. MD, DO, chiropractor, physical therapist)', apiPath: '' }, //  doctor
      { key: '2', type: 'hospital', payloadType: 2, value: 'Hospital – general acute care and pediatric/children’s', apiPath: '/api/providers/hospitals' }, // hospital
      { key: '3', type: 'ground-amb', payloadType: 3, value: 'Ambulance - Ground', apiPath: '' }, // ground-amb
    ]
  },
  {
    category: 'Hospital',
    options: [
      { key: '2-1', type: 'hospital', payloadType: 2, value: 'General Acute Care and Pediatric/Children’s', apiPath: '/api/providers/hospitals' }, //hospital
      { key: '2-2', type: 'inpatient-rehabilitation-facility', payloadType: 2, value: 'Rehabilitation', apiPath: '', facilityTypeParameter: 'inpatient-rehabilitation-facility' }, // healthcare-facility
      { key: '2-3', type: 'long-term-care-hospital', payloadType: 2, value: 'Long Term Care', apiPath: '', facilityTypeParameter: 'long-term-care-hospital' }, // healthcare-facility
    ]
  },
  {
    category: 'Facility',
    options: [
      { key: '3-1', type: 'amb-surgery-center', payloadType: 2, value: 'Ambulatory Surgery Center', apiPath: '' }, // amb-surgery-center
      { key: '3-2', type: 'skilled-nursing-facility', payloadType: 2, value: 'Skilled Nursing Facility', apiPath: '' }, //skilled-nursing-facility
      { key: '3-3', type: 'dialysis-facility', payloadType: 2, value: 'Dialysis Center', apiPath: '' }, // dialysis-facility
      { key: '3-4', type: 'sleep-center', payloadType: 2, value: 'Sleep Center', apiPath: '' }, // sleep-center
      { key: '3-5', type: 'infusion-center', payloadType: 2, value: 'Infusion center', apiPath: '' }, // infusion-center
    ]
  },
  {
    category: 'Ambulance',
    options: [
      { key: '4-1', type: 'ground-amb', payloadType: 3, value: 'Ground', apiPath: '' }, // ground-amb
      { key: '4-2', type: 'air-amb', payloadType: 4, value: 'Air', apiPath: '' }, // air-amb
    ]
  },
  {
    category: 'Ancillary',
    options: [
      { key: '5-1', type: 'imaging-center', payloadType: 5, value: 'Imaging center (x-ray, MRI, CT scan, etc.)', apiPath: '' }, //imaging-center
      { key: '5-2', type: 'durable-med-equipment', payloadType: 5, value: 'Durable Medical Equipment', apiPath: '' }, // durable-med-equipment
      { key: '5-3', type: 'clinical-lab', payloadType: 5, value: 'Clinical laboratory', apiPath: '' }, // clinical-lab
      { key: '5-4', type: 'home-health-care-agency', payloadType: 5, value: 'Home Health, Nursing, & Infusion', apiPath: '', facilityTypeParameter: 'home-health-care-agency' }, // healthcare-facility
      { key: '5-5', type: 'home-dialysis', payloadType: 5, value: 'Home Dialysis', apiPath: '' }, // home-dialysis
      { key: '5-6', type: 'hospice', payloadType: 5, value: 'Hospice', apiPath: '', facilityTypeParameter: 'hospice' }, // hospice
    ]
  },
  {
    category: 'Other',
    options: [
      { key: '6-1', type: 'not-sure', payloadType: 6, value: 'I’m not sure.', apiPath: '' }, // not-sure
    ]
  }
];