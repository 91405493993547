/* import api from '../api.js'
import ServiceResponse from './ServiceResponse.js' */
import api from '@/util/api.js'
import ServiceResponse from '@/util/services/ServiceResponse.js'

const CaseManagementService = {
  /**
   * Finds insurance settings for a given issue.
   *
   * @param {int} id
   * @returns {Promise<ServiceResponse>}
   */
  complete: async (id) => {
    try {
      const { data } = await api({
        url: `/api/issues/${id}/case-management/complete`,
        method: 'POST',
      })

      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('CaseManagementService.complete: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },
  stop: async (id) => {
    try {
      const { data } = await api({
        url: `/api/issues/${id}/case-management/stop`,
        method: 'POST',
      })

      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('CaseManagementService.stop: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },
  lock: async (id) => {
    try {
      const { data } = await api({
        url: `/api/issues/${id}/case-management/lock`,
        method: 'POST',
      })

      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('CaseManagementService.lock: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },
  disable: async (id) => {
    try {
      const { data } = await api({
        url: `/api/issues/${id}/case-management/disable`,
        method: 'POST',
      })

      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('CaseManagementService.disable: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },
  reopen: async (id) => {
    try {
      const { data } = await api({
        url: `/api/issues/${id}/case-management/reopen`,
        method: 'POST',
      })

      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('CaseManagementService.reopen: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },
}

export default CaseManagementService
