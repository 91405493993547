import type { RouteRecordRaw, Router } from 'vue-router'

const moduleRoute: RouteRecordRaw = {
  path: '/issues/:slug/',
  meta: { title: "Case #" },
  children: [
    // {
    //   path: 'core',
    //   component: () => import('@/view/issues/steps/WhoAreYou.vue'),
    //   meta: {
    //     layout: 'BaseLayout',
    //   },
    // },
    {
      path: 'fast-track',
      name: 'issues.fastTrack',
      component: () => import('../pages/FastTrack.vue'),
      alias: '/issues/:slug/fast-track',
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      }
    },
    {
      path: 'payment',
      name: 'issues.paymentPage',
      component: () => import('../pages/PaymentPage.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'state-specific',
      name: 'issues.stateSpecific',
      component: () => import('../pages/StateSpecific.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'invoice',
      name: 'issues.invoicePage',
      component: () => import('../pages/InvoicePage.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'not-satisfied',
      name: 'issues.notSatisfiedPage',
      component: () => import('../pages/NotSatisfiedPage.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'settings',
      name: 'issues.settingsPage',
      component: () => import('../pages/SettingsPage.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'locking-means-loosing',
      name: 'issues.satisfactionGuarantee',
      component: () => import('../pages/LockingMeansLosingPage.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
  ],
}

export default (router: Router) => {
  router.addRoute(moduleRoute)
}
